import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import app from './app'
import admin from './admin'
import mainMenu from './mainMenu'
import termReports from './termReports'
import markSheet from './markSheet'
import printViewReports from './printViewReports'
import timetable from './timetable'
import subjectAssignments from './subjectAssignments'
import editViewStudents from './editViewStudents'
import employees from './employees'
import settings from './settings'
import axios from 'axios'
import students from './students'
import studentAttendance from './studentAttendance'

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_URI

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth,         
        admin,
        app,
        mainMenu,
        termReports,
        markSheet,
        printViewReports,
        timetable,
        subjectAssignments,
        editViewStudents,
        employees,
        settings,
        students,
        studentAttendance
    }
})