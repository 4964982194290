<template>
     <v-row>           
        <v-col>
            <v-card
                class="pa-0 mx-auto"
                width="1000"
            >
                <v-card-title>
                    Edit / View Students                   
                    
                    <v-spacer></v-spacer>

                    <students-attendance
                    ></students-attendance>

                    <v-dialog
                        v-model="dialog"
                        max-width="700px"
                        persistent
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                outlined
                                text
                                v-bind="attrs"
                                v-on="on"
                                class="mr-3"
                            >
                                Add New Student
                            </v-btn>
                        </template>
                        
                        <v-card ref="form">
                            <v-card-title>
                                <span class="headline">{{ formTitle }}</span>
                            </v-card-title>                        
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        
                                        <v-col cols="5">
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-card
                                                        outlined
                                                        class="mx-auto"
                                                        width="200"
                                                        height="230"
                                                    >
                                                        <v-img 
                                                            :src="editedItem.picture"
                                                            height="228"
                                                            contain
                                                        >
                                                        </v-img>
                                                    </v-card>
                                                </v-col>
                                            </v-row>

                                            <v-row>
                                                <v-col cols="12">
                                                    <v-card
                                                        class="mx-auto"
                                                        width="200"
                                                        flat
                                                    >
                                                        <v-file-input
                                                            :value="pictureFile"
                                                            @change="uploadPicture"
                                                            placeholder="Edit Picture"
                                                            append-icon="mdi-camera"
                                                            prepend-icon=""
                                                            hide-details="auto"
                                                            outlined
                                                            dense
                                                        ></v-file-input>
                                                    </v-card>
                                                </v-col>
                                            </v-row>

                                            <v-row v-if="pictureFile">
                                                 <v-card
                                                    class="mx-auto"
                                                    width="200"
                                                    flat
                                                >
                                                    <v-progress-linear
                                                        v-model="progress"
                                                        color="primary"
                                                        height="25"
                                                        reactive
                                                    >
                                                        <strong>{{ progress }} %</strong>
                                                    </v-progress-linear>
                                                </v-card>
                                            </v-row>

                                            <v-row v-if="pictureUploadMessage">
                                                <v-alert  border="left" color="primary" dark>
                                                    {{ pictureUploadMessage}}
                                                </v-alert> 
                                            </v-row>

                                            <v-row>
                                                <v-col cols="12">
                                                    <v-card
                                                        class="mx-auto"
                                                        width="200"
                                                        flat
                                                    >
                                                        <v-text-field                                                        
                                                            label="Student ID#"
                                                            v-model="editedItem.student_id"
                                                            hide-details="auto"
                                                            disabled
                                                            outlined
                                                            filled
                                                            dense
                                                        ></v-text-field>
                                                    </v-card>    
                                                </v-col>
                                            </v-row>

                                            <v-row>
                                                <v-col cols="12">
                                                    <v-card
                                                        class="mx-auto"
                                                        width="200"
                                                        flat
                                                    >
                                                        <div class="d-flex align-center">
                                                            <v-text-field
                                                                v-model="editedItem.serial_number"                                                        
                                                                label="ID Card Serial Number"
                                                                hide-details="auto"
                                                                :disabled="lockSerialNumber"
                                                                outlined
                                                                :filled="lockSerialNumber"
                                                                dense
                                                            ></v-text-field>

                                                            <v-btn
                                                                icon
                                                                small
                                                                @click="lockSerialNumber=!lockSerialNumber"
                                                            >
                                                                <v-icon>{{ lockIcon }}</v-icon>
                                                            </v-btn>
                                                        </div>    
                                                    </v-card>    
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        
                                        <v-col cols="7">
                                            <v-card class="pa-3" flat>
                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-text-field
                                                            ref="first_name"
                                                            v-model="editedItem.first_name"
                                                            label="First Name"
                                                            hide-details="auto"
                                                            :rules="rules"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>

                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-text-field
                                                            ref="last_name"
                                                            v-model="editedItem.last_name"
                                                            label="Last Name"
                                                            hide-details="auto"
                                                            :rules="rules"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>

                                                <v-row>
                                                    <v-col cols="12">                                            
                                                        <v-menu
                                                            ref="menu"
                                                            v-model="menu"
                                                            :close-on-content-click="false"
                                                            transition="scale-transition"
                                                            offset-y
                                                            min-width="290px"                   
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                v-model="date"
                                                                label="Date of Birth"                                                    
                                                                readonly
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                hide-details
                                                            ></v-text-field>
                                                            </template>
                                                            <v-date-picker
                                                                ref="picker"
                                                                v-model="date"                                                
                                                                min="1950-01-01"
                                                                @change="saveDate"                                                
                                                            ></v-date-picker>
                                                        </v-menu>
                                                    </v-col>
                                                </v-row>

                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-text-field
                                                            v-model="editedItem.birth_certificate_pin"
                                                            label="Birth Certificate Pin"
                                                            hide-details
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>

                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-select
                                                            v-model="editedItem.gender"
                                                            :items="gender"
                                                            label="Gender"
                                                            hide-details
                                                        ></v-select>
                                                    </v-col>
                                                </v-row>

                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-autocomplete
                                                            ref="form_class_id"
                                                            v-model="editedItem.form_class_id"
                                                            :items="formClasses"
                                                            item-text="id"
                                                            item-value="id"
                                                            label="Form Class"
                                                            hide-details="auto"
                                                            :rules="rules"
                                                        ></v-autocomplete>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                    </v-row>            
                                </v-container>    
                            </v-card-text> 
                            <v-card-actions
                                class="px-4"
                            >
                                <span
                                    v-show="saved"
                                >
                                    Data saved
                                    <v-icon
                                        small
                                        color="primary"                                        
                                        class="ml-3"
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </span>

                                <span
                                    v-show="error"
                                    class="red--text"
                                >
                                    {{ errorMessage }}
                                    <v-icon
                                        small
                                        color="red"
                                        class="ml-3"
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </span>

                                <v-spacer></v-spacer>
                                <v-btn                                    
                                    text
                                    @click="close"
                                >
                                    Close
                                </v-btn>
                                <v-btn
                                    color="primary"                                    
                                    dark                                   
                                    @click="save"
                                >
                                    Save
                                </v-btn>                                
                            </v-card-actions>
                            <v-overlay
                                :value="overlay"
                                opacity="0.8"
                                z-index="10"
                                absolute                    
                            >
                                
                                <v-progress-circular
                                    indeterminate
                                    size="64"
                                ></v-progress-circular>                   
                            </v-overlay>
                        </v-card>       
                    </v-dialog>

                    <students-upload
                        v-on:update-students="initialize"
                    ></students-upload>
                    
                </v-card-title>

                <div class="pl-2">
                    <v-btn 
                        :color="current.color" 
                        :depressed="current.depressed" 
                        class="ma-2" 
                        x-small
                        tile
                        @click="filterCurrent()"
                    >
                    <v-icon v-if="currentStudents" small left light>mdi-check-circle</v-icon>
                        Current
                    </v-btn>
                        <v-btn 
                        :color="archive.color" 
                        :depressed="archive.depressed" 
                        class="ma-2" 
                        x-small
                        tile
                        @click="filterArchive()"
                    >
                        <v-icon v-if="!currentStudents" left light>mdi-check-circle</v-icon>
                        Archive
                    </v-btn>
                </div>

                <v-card-text>
                    <v-data-table
                        :headers="headers"
                        :items="students"
                        fixed-header                        
                        height="45vh"
                        :calculate-widths="true" 
                        :search="search"
                        :loading="loading"
                        :expanded.sync="expanded"
                        item-key="student_id"
                    >
                        <template v-slot:top>
                            <v-spacer></v-spacer>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details
                                class="mb-8"
                                clearable
                            ></v-text-field>

                           <!-- Update Status Dialog -->
                            <v-dialog
                                v-model="dialogUpdateStatus"
                                max-width="430px"
                                persistent
                            >
                                <v-expand-transition>
                                    <v-card
                                        v-show="updatingStatus"
                                        color="primary"
                                        dark
                                        class="pa-4"
                                    >
                                        <v-card-text>
                                        {{ studentStatusUpdated }}
                                        <v-progress-linear
                                                indeterminate
                                                color="white"
                                                class="mb-0 mt-4"
                                        ></v-progress-linear>
                                        </v-card-text>
                                    </v-card>
                                </v-expand-transition>
                            </v-dialog>
                        </template>

                        <template v-slot:item="props">
                            <tr>
                                <td>
                                    <v-img
                                        :src="props.item.picture"
                                        max-width="50"
                                        class="my-4"
                                    >
                                        <template v-slot:placeholder>
                                            <v-row
                                                class="fill-height ma-0"
                                                align="center"
                                                justify="center"
                                            >
                                                <v-progress-circular
                                                    indeterminate
                                                    color="primary"
                                                ></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>
                                </td>
                                <td>{{ props.item.student_id }}</td>
                                <td>{{ props.item.name }}</td>
                                <td>{{ props.item.form_class_id }}</td>
                                <td>{{ props.item.gender }}</td>
                                <td>{{ props.item.date_of_birth }}</td>

                                <td>
                                    <v-menu
                                        v-model="props.item.menu"
                                        :close-on-content-click="false"
                                        :close-on-click="false"
                                    >
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-btn
                                                color="primary"
                                                dark
                                                v-bind="attrs"
                                                v-on="on"
                                                tile
                                                x-small
                                            >
                                                {{ props.item.student_status }}
                                            </v-btn>
                                        </template>

                                        <v-card>
                                            <v-card-title>Change Student Status</v-card-title>
                                            <v-divider></v-divider>
                                            <v-card-text>
                                                <v-radio-group class="mt-0" v-model="props.item.student_status_id">
                                                    <v-radio
                                                        v-for="status in statuses"
                                                        :key="status.id"
                                                        :label="status.detail"
                                                        :value="status.id"
                                                        class="mt-3"
                                                    ></v-radio>
                                                </v-radio-group>
                                            </v-card-text>

                                            <v-card-actions class="flex-column align-stretch">
                                                <v-row>
                                                    <v-col>
                                                        <v-btn 
                                                            block 
                                                            small 
                                                            color="primary"
                                                            @click="updateStudentStatus(props.item)"
                                                        >
                                                            Change Status
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
                                                        <v-btn 
                                                            block 
                                                            small 
                                                            depressed 
                                                            @click="props.item.menu = false"
                                                        >
                                                            <v-icon left>mdi-close</v-icon>
                                                            Close
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card-actions>
                                            
                                        </v-card>
                                    </v-menu>
                                </td>

                                <td>
                                    <v-btn
                                        tile
                                        color="primary"
                                        @click="editStudent(props.item)"
                                        x-small
                                        block
                                    >
                                        Edit/View
                                        <v-icon
                                            x-small
                                            right
                                        >
                                            mdi-pencil
                                        </v-icon>
                                        
                                    </v-btn>
                                </td>
                            </tr>
                        </template>

                    </v-data-table>

                    <v-snackbar
                        v-model="snack"
                        :timeout="3000"
                        color="primary"
                    >
                        {{ snackText }}
                        <template v-slot:action="{ attrs }">
                            <v-btn
                                v-bind="attrs"
                                text
                                @click="snack = false"
                            >
                                Close
                            </v-btn>
                        </template>
                    </v-snackbar>
                </v-card-text>                
            </v-card>            
        </v-col>
     </v-row>            
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import StudentsUpload from './EditViewStudentsUpload.vue';
import StudentsAttendance from './StudentsAttendance.vue';
import UploadService from "../services/UploadFileService";
export default {
    created () {
        this.initialize();
    },

    components: {
        StudentsUpload,
        StudentsAttendance,
    },

    data: () => ({
       headers: [
            {text: 'Picture', align: 'start', sortable: false, value: 'picture', width: '100'},
            {text: 'ID', align: 'start',  value: 'student_id', width: '100'},
            {text: 'Name', align: 'start', sortable: false, value: 'name', width: '180'},
            {text: 'Class', align: 'start',  value: 'form_class_id', width: '100'},
            {text: 'Gender', align: 'start', sortable: false, value: 'gender', width: '80'},
            {text: 'DOB', align: 'start', value: 'data_of_birth', width: '110'},
            {text: 'Status', align: 'start', value: 'student_status', width: '120'},
            {text: '', align: 'start', sortable: false, value: 'actions', },
        ],
        students: [],
        search: '',
        loading: false,
        snack: false,
        snackText: '',
        options: {
            itemsPerPage: -1,
        },
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {
            first_name: '',
            last_name: '',
            date_of_birth: '',
            birth_certificate_pin: '',
            gender: '',
            form_class_id: ''
        },
        deletedItem: {
            first_name: '',
            last_name: '',
            date_of_birth: '',
            birth_certificate_pin: '',
            gender: '',
            form_class_id: ''
        },
        defaultItem: {
            first_name: '',
            last_name: '',
            date_of_birth: '',
            birth_certificate_pin: '',
            gender: '',
            form_class_id: ''
        },
        gender: ['M', 'F'],
        formClasses: [],
        menu: false,
        date: null,
        expanded: [],
        singleExpand: false,
        overlay: false,
        saved: false,
        error: false,
        rules: [
            value => !!value || 'Required'
        ],
        formHasErrors: false,
        errorMessage: '',
        statuses: [],
        status: 3,
        deleteStudentSelected: null,
        confirmDelete: true,
        deleting: false,
        pictureFile: null,
        pictureUploadMessage: null,
        progress: 0,
        lockSerialNumber: true,
        updatingStatus: false,      
        current: {
            color: 'primary',
            depressed: false,
        },
        archive: {
            color: 'gray',
            depressed: true
        },
        currentStudents: true,
        dialogUpdateStatus: false,  
    }),

    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'New Student' : 'Student Profile'
        },
        studentStatusUpdated () {
            return `Updating ${this.deletedItem.first_name} ${this.deletedItem.last_name} Status`;
        },

        lockIcon () {
            if(this.lockSerialNumber) return "mdi-lock";
            return "mdi-lock-open";
        }

    },

    watch: {
        dialog (val) {
            val || this.close()
        },
         dialogUpdateStatus (val) {
            val || this.closeDelete()
        },
        menu (val) {
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
        },
        date (val) {
            this.editedItem.date_of_birth = val;
        }
    },

    methods: {
        ...mapActions({
            getStudentsAll: 'students/getStudentsAll',
            getFormClasses: 'termReports/getFormClassesList',
            postStudent: 'editViewStudents/postStudent',
            getStudentStatus: 'editViewStudents/getStudentStatus',
            delete: 'students/deleteStudent',
        }),
        ...mapMutations({
            setStudent: 'editViewStudents/setStudent',
            setEditedStudent: 'students/setEditedStudent',
        }),
        async initialize(){
            this.loading = true;
            try {

                const [ 
                    { data: students }, 
                    { data: formClasses },
                    { data: dataStudentStatuses}
                ] = 
                await Promise.all([
                    this.getStudentsAll(), 
                    this.getFormClasses(),
                    this.getStudentStatus()
                ]);

                this.mapStudents(students);
                this.formClasses = formClasses;
                dataStudentStatuses.forEach(record => {
                    if(record.detail !== 'Graduated'){
                        this.statuses.push(record);
                    }
                })            
                this.loading = false;
            } catch (error) {
                error.response ? console.log(error.response) : console.log(error);
            }
            this.loading = false;
        },

        mapStudents (data) {
            //console.log('students', data)
            const { current_students, archived_students } = data;
            // this.studentsCurrent = [];
            // this.studentsArchive = [];

            this.studentsCurrent = current_students.map(value => {
                    value.name = value.last_name + ', ' + value.first_name;
                    if(!value.picture){
                        value.picture = require("../assets/icons/student.svg")
                    }
                    return value;
                });

            this.students = this.studentsCurrent;

            this.studentsArchive = archived_students.map(value => {
                value.name = value.last_name + ', ' + value.first_name;
                if(!value.picture){
                    value.picture = require("../assets/icons/student.svg")
                }
                return value;
            })
        },
        editStudent(item){
            //console.log(`Edit:`, item);
            this.editedIndex = this.students.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.date = item.date_of_birth;
            this.dialog = true;
        },

        close () {
            this.dialog = false;
            this.error = false;
            this.saved = false;
            this.date = null;
            this.$nextTick( () => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
            Object.keys(this.editedItem).forEach(f => {
                if(f === 'first_name' || f === 'last_name' || f === 'form_class_id'){
                    this.$refs[f].reset()
                }
                
            })
        },

        async save () {
            console.log('saving...')
            this.overlay = true
            console.log(this.editedItem);
            this.setStudent(this.editedItem);
            this.formHasErrors = false;
            //console.log(Object.keys(this.editedItem));
            Object.keys(this.editedItem).forEach( f => {
                if(f === 'first_name' && !this.editedItem[f]){
                    this.formHasErrors = true;
                    this.$refs[f].validate(true);
                } 
                if(f === 'last_name' && !this.editedItem[f]){
                    this.formHasErrors = true;
                    this.$refs[f].validate(true);
                } 
                if(f === 'form_class_id' && !this.editedItem[f]){
                    this.formHasErrors = true;
                    this.$refs[f].validate(true);
                } 
               
            })
            if(!this.formHasErrors){
                console.log('fields present');
                try {
                    let response = await this.postStudent();
                    console.log(response);
                    // let studentsPromise = await this.getStudents();
                    // this.students = studentsPromise.data;
                    this.initialize();
                    //console.log(response);
                    this.error = false;
                    this.saved = true;  
                } catch (error) {
                    console.log(error.response);
                    this.errorMessage = 'Error Occured';
                    this.saved = false; 
                    this.error = true;                    
                }
            }
            else{
                //console.log('fields empty');
                this.saved = false; 
                this.error = true;
                this.errorMessage = 'Required Fields Empty'; 
            }
            
            this.overlay = false;
                     
        },

        updateStudentStatus(item) {
            const index = this.students.findIndex(student => student.student_id === item.student_id);
            this.students[index].menu = false;
            this.deletedItem = Object.assign({}, item);
            this.deleteStudentSelected = item.first_name + ' ' + item.last_name;
            if(item.student_status_id === 1 && this.currentStudents) return;
            this.updateStudentStatusConfirm();
        },

        addStudent(){
            //
        },

        saveDate(date){
            this.$refs.menu.save(date);
        },

        closeDelete(){
            this.dialogDelete = false;
            this.status = 3;            
        },

        async updateStudentStatusConfirm(){
            console.log('updatingStatus...');
            this.dialogUpdateStatus = true;
            this.updatingStatus = true;
            this.setEditedStudent({
                student_id: this.deletedItem.student_id,
                student_status_id: this.deletedItem.student_status_id
            });

            try {
                const response = await this.delete();
                console.log(response)
                const { data } = await this.getStudentsAll();
                this.mapStudents(data);             
                this.updatingStatus = false; 
                if(!this.currentStudents) this.filterArchive();                              
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            
            setTimeout(() =>{
                this.dialogUpdateStatus = false;                 
            })

            setTimeout(() => {
                this.snack = true;
                this.snackText = this.deletedItem.first_name + ' ' + this.deletedItem.last_name + ' status updated.'
            })           
            
        },

        async uploadPicture (file) {
            if(!file) return;
            
            this.progress = 0;
            this.pictureFile = file;
            this.editedItem.picture = null;
            this.loading = true;
            this.pictureUploadMessage = null;

            try {
                const { data } = await UploadService.uploadPicture(
                this.pictureFile, 
                this.editedItem.student_id,
                'picture',
                (event) => {
                    this.progress = Math.round((100 * event.loaded) / event.total);
                    } 
                )
                this.editedItem.picture = data.picture;
                const response = await this.getStudents();
                this.mapStudents(response);
                
            } catch (error) {
               if(error.response) console.log(error.response);
               else console.log(error); 
            }
            this.pictureFile = null;
            this.loading = false;
            
        },

        unlockSerialNumber () {

        },

        filterArchive () {
            this.archive.color = 'primary';
            this.archive.depressed = false;
            this.current.color = 'gray';
            this.current.depressed = true;
            this.students = [];
            this.currentStudents = false;
            this.$nextTick(() => {
                this.students = this.studentsArchive
            })
        },

        filterCurrent () {
            this.archive.color = 'gray';
            this.archive.depressed = true;
            this.current.color = 'primary';
            this.current.depressed = false;
            this.students = [];
            this.currentStudents = true;
            this.$nextTick(() => {
                this.students = this.studentsCurrent
            })
        },

    },

    
}
</script>