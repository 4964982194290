<template>
    <v-dialog
        :value="dialog"
        max-width="900px"
        persistent
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                outlined
                text
                @click="dialog=true"
                v-on="on"
                v-bind="attrs"
                class="mr-2"
            >
                View Attendance
            </v-btn>                            
        </template>

        <!-- <div style="position:relative; height:40px">
            <v-btn fab dark small color="red" style="position:absolute">
                <v-icon dark>mdi-close</v-icon>
            </v-btn>
        </div> -->

        <v-card pa-3 flat width="900px" >
            <v-system-bar  window color="primary" dark>
                <v-spacer></v-spacer>
                <v-btn small icon @click="dialog=!dialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-system-bar>

            <v-toolbar color="primary" dark lights-out>
                <v-toolbar-title> Student Attendance - {{ formattedDate }} </v-toolbar-title>
            </v-toolbar>

            <v-container fluid>
                <v-row>
                    <v-col cols="8">
                        <v-card  flat>
                            <ChartBar
                                v-if="dataLoaded"
                                :chart-data="chartData"
                            ></ChartBar>

                            <v-card-actions class="pt-3 pb-0">
                                <v-btn text color="primary">Total Attendance: {{ totalAttendance }}</v-btn>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary">Late Attendance: {{ lateAttendance }}</v-btn>
                            </v-card-actions>

                            <div class="mt-4 px-1">
                                <v-btn 
                                    small 
                                    outlined 
                                    class="mr-2" 
                                    color="primary"
                                    @click="displayReport('dailyAttendance')"
                                >
                                    Daily Attendance
                                </v-btn>

                                <v-btn 
                                    small
                                    outlined 
                                    class="mr-2" 
                                    color="primary"
                                    @click="displayReport('classAttendance')"
                                >
                                    Class Attendance
                                </v-btn>
                                <!-- <v-btn small outlined class="mr-2" color="primary">Class Attendance</v-btn>
                                <v-btn small outlined class="mr-2" color="primary">Summary Statistics</v-btn> -->
                            </div>

                            <v-overlay
                                absolute
                                :value="overlay"                    
                                color="grey lighten-5"
                                opacity="0.9" 
                            >
                                <v-progress-circular
                                    indeterminate 
                                    size="64"                        
                                    color="primary"
                                ></v-progress-circular>
                            </v-overlay>
                        </v-card>
                    </v-col>

                    <v-col
                        cols="4"
                        class="d-flex align-end flex-column"
                    >
                        <v-date-picker
                            label="Select Date Range"
                            v-model="selectedDate"
                            elevation="6"
                            @change="setDate"
                            full-width
                            :allowed-dates="allowedDates"
                        ></v-date-picker>
                        <v-btn
                            depressed
                            block
                            class="mt-auto flex-grow-0"
                            @click="closeAttendance"
                            small
                        >
                            Close Attendance
                        </v-btn>
                    </v-col>
                </v-row> 

            </v-container>

            <v-dialog
                v-model="reportViewer"
                max-width="700px"
                persistent
            >
                <v-card
                    v-if="reportViewer"
                    height="90vh"
                    style="position:relative;" 
                >                
                    <iframe 
                        ref="pdf" 
                        style="width:100%; height:92%"                      
                        :src="src"
                        @load="pdfLoaded"
                    ></iframe>              
                    <v-overlay
                        absolute
                        :value="reportViewerOverlay"
                        color="white"
                        opacity="0.60"
                    >
                        <v-progress-circular
                            indeterminate
                            size="64"
                            color="primary"
                        ></v-progress-circular>
                    </v-overlay>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn 
                            depressed 
                            @click="closeReport"
                        >
                            <v-icon left>mdi-close</v-icon>
                            Close Report
                        </v-btn>
                    </v-card-actions> 
                </v-card>
            </v-dialog> 

            <v-snackbar
                v-model="snackbar.visible"
                :color="snackbar.color"
                @input="onSnackbarClose"
                @click:close="onSnackbarClose"
            >
                {{ snackbar.text }}
                <template v-slot:action="{ attrs }">
                    <v-btn
                        text
                        v-bind="attrs"
                        @click="closeSnackbar"
                    >
                        Close
                    </v-btn>
                </template>
            </v-snackbar> 


        </v-card>              
    </v-dialog>    
</template>

<script>
import ChartBar from './ChartBar.vue'
import { format, parseISO } from 'date-fns';
import { mapActions, mapMutations } from 'vuex';
export default {
    components: {
        ChartBar,
    },

    created () {
        // console.log('created')
        // this.initialize();
        
    },

    watch: {
        dialog: function (val) {
            if(val) this.initialize();
        },
    },

    data: () => ({
        dialog: false,
        selectedDate: null,
        dataLoaded: false,
        chartData: {
            labels: ['Form 1', 'Form 2', 'Form 3', 'Form 4', 'Form 5', 'Form 6',],
            datasets: []
        },
        overlay: false,
        totalAttendance: '--',
        lateAttendance: '--',
        formattedDate: null,
        enabledDates: [],
        reportViewer: false,
        reportViewerOverlay: false,
        src: null,
        snackbar: {
            display: false,
            text: null,
            color: null
        }
    }),

    methods: {
        ...mapActions({
            getAttendanceSummaryData: 'studentAttendance/getAttendanceSummaryData',
        }),

        ...mapMutations({
            setAttendanceDate: 'studentAttendance/setAttendanceDate',
        }),

        initialize () {
            const todayFormatted = format(new Date(), 'yyyy-MM-dd');
            this.setAttendanceDate(todayFormatted);
            this.formattedDate = format(new Date(), 'EEEE, d LLLL yyyy');
            this.selectedDate = todayFormatted;
            this.getChartData();
        },

        setDate () {
            this.formattedDate = format(parseISO(this.selectedDate), 'EEEE, d LLLL yyyy');
            this.setAttendanceDate(this.selectedDate)
            this.getChartData();            
        },

        async getChartData () {
            this.overlay = true;
            try {
                const { data } = await this.getAttendanceSummaryData();
                if(!Array.isArray(data)) 
                this.mapChartData(data);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.overlay = false;
        },

        mapChartData (data)
        {
            console.log('attendance data: ', data)
            let dataSets = [];
            let dataPresent = [];
            let dataAbsent = [];
            this.chartData.datasets = [];
            
            const [startDate, availableDates] = Object.values(data);
            
            this.enabledDates = availableDates;
            const { attendance_records } = startDate;
            const { attendance_school: { attendance: attendanceSchool } } = startDate;
            const { attendance_late: { attendance: attendanceLate }} = startDate;

            this.totalAttendance = attendanceSchool;
            this.lateAttendance = attendanceLate;
            Object.keys(attendance_records).forEach(formLevel => {
                dataPresent.push(attendance_records[formLevel].attendance);
                dataAbsent.push(attendance_records[formLevel].total - attendance_records[formLevel].attendance)
            })

            dataSets.push(
                {
                    label: 'Present',
                    data: dataPresent,
                    // backgroundColor:'rgba(54, 162, 235, 0.2)',
                    backgroundColor:'rgba(48, 88, 172, 0.4)',
                }
            )
            dataSets.push(
                {
                    label: 'Absent',
                    data: dataAbsent,
                    backgroundColor: 'rgba(190, 190, 190, 0.2)',                           
                }
            )
            this.chartData.datasets = dataSets;
            this.dataLoaded = true;
        },

        allowedDates (val) {
            return this.enabledDates.includes(val);
        },

        clearDates ()
        {
            this.dateRange = [];
        },

        closeAttendance ()
        {
            this.dialog = false;
        },

        displayReport (report)
        {
            if(!this.enabledDates.includes(this.selectedDate))
            {
                this.snackbar.text = "Invalid Date Selected";
                this.snackbar.color = "red";
                this.snackbar.visible = true;
                return;
            }

            const reports = {
                'dailyAttendance': process.env.VUE_APP_API_URI + "/api/attendance-report-daily?attendance_date=" + this.selectedDate,
                'classAttendance': process.env.VUE_APP_API_URI + "/api/attendance-report-class?attendance_date=" + this.selectedDate,
            }

            for(let key in reports){
                if(key === report){
                    this.src = reports[key];
                    break;
                }
            }

            this.reportViewerOverlay = true;
            this.reportViewer = true;
        },

        pdfLoaded () 
        {
            this.reportViewerOverlay = false;
        },

        closeReport () 
        {
            this.reportViewer = false;
            this.src = null;
        },

        onSnackbarClose (value) {
            if(!value) {
                this.snackbar =  {
                    display: false,
                    text: null,
                    color: null
                };
            }

        },

        closeSnackbar () {
            console.log('close snackbar')
            this.snackbar =  {
                display: false,
                text: null,
                color: null
            };
        }




    }
}
</script>

<style scoped>
    .v-system-bar--window .v-icon{
        margin-right: 0px;
    }
</style>